.invite-complete-wrapper {
  margin: auto 90px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.invite-org-complete {
  display: flex;
  width: 100%;
  height: 100vh;
  margin-bottom: auto;
  font-family: Roboto, serif;
  background: white;

  &-main {
    margin: auto;
  }
}
