@import "styles/colors.scss";

.app-version {
  &-text {
    position: fixed;
    bottom: 10px;
    left: 16px;
    border-radius: 5px;
    color: $note-message-green;
    line-height: 22px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &-title {
    font-weight: bold;
    font-size: 20px;
  }

  &-content {
    height: 500px;
    overflow-y: auto;
    padding-right: 20px;
    margin-top: 15px;

    & > p:first-child {
      margin-top: 0;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: gray;
    }
  }
}
