.invite-complete {
  &-form {
    margin-top: 44px;

    &-title {
      color: #0C758C;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &-subtitle {
      font-size: 16px;
      font-weight: bold;
      color: #0B404B;
      margin-bottom: 20px;
    }

    &-button {
      display: flex;
      margin: 35px auto 0;
    }
  }

  .hr {
    background: #E8F0F2;
    width: 100%;
    height: 1px;
    margin: 25px 0;
  }
}
